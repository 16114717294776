<template>
	<div>
		<div class="telemedicine-management pr-3">
			<div class="row">
				<div class="col-sm-8">
					<div class="d-flex py-1 align-items-center">
						<img src="../../../public/assets/images/icon/icon_contact_blue.svg" alt="" class="mr-2"><span class="txt-pri page-title">Danh sách khách hàng gần đây</span>
					</div>
				</div>
				<div align="right" class="col-sm-4">
					<button class="btn bg-pri bd-pri text-white" @click="donwloadFileExcPerson()">Tải xuống</button>
				</div>
			</div>
			<div class="card radius-10 pt-2 pl-4 pr-4 border-0">
				<div class="table-responsive">
					<table class="table table-vcenter table-mobile-md card-table table-scroll">
						<thead>
							<tr>
								<th>Tên khách hàng</th>
								<th>Nghề nghiệp</th>
								<th>Địa chỉ</th>
								<th>HODO account</th>
								<th class="sort-both" style="background-repeat: no-repeat; background-position: center right;" :class="sort_create === 1 ? 'sort-up' : sort_create === 2 ? 'sort-down' : 'sort-both'" @click="sortByCreatedAt()">Created at</th>
								<th class="sort-both" style="background-repeat: no-repeat; background-position: center right;" :class="sort_last === 1 ? 'sort-up' : sort_last === 2 ? 'sort-down' : 'sort-both'" @click="sortByLastLogin()">Last login</th>
							</tr>
						</thead>
						<tbody v-if="persons" class="h-100">
							<tr v-for="person in persons.data" :key="person.id" class="border-bottom">
								<td data-label="Thông khách hàng" class="table-td-name-avatar">
									<div class="d-flex py-1 align-items-center">
										<div class="flex-fill">
											<p class="p-0 m-0">{{person && person.person && person.person.name ? person.person.name : ''}}</p>
										</div>
									</div>
								</td>
								<td data-label="Nghề nghiệp">{{person && person.person && person.person.career ? person.person.career : ''}}</td>
								<td data-label="Địa chỉ">{{person && person.person && person.person.address ? person.person.address : ''}}</td>
								<td data-label="HODO account">
									<div class="pt-2 pb-2">{{person && person.username ? person.username : ''}}</div>
								</td>
								<td data-label="Created at" v-html="person.created_at ? formatDateDMY(person.created_at) : ''"></td>
								<td data-label="Last login" v-html="person.last_login ? formatDateDMY(person.last_login) : ''"></td>
							</tr>
							<tr  v-if="persons && !persons.count">
								<td colspan="6">
									<div class="col-sm-4 offset-sm-4 pt-3 pb-5 text-center">
										<img src="../../../public/assets/images/not-found-search.png" class="img-responsive p-0" alt="">
										<p class="robo-16-400 text-black">{{'Chưa có khách hàng nào!!'}}</p>
									</div>
								</td>
							</tr>
							<!-- <tr v-for="i in 5" v-if="loading">
								<td>
									<div class="skeleton-avatar avatar-sm avatar-rounded">
									</div>
								</td>
								<td>
									<div class="skeleton-line"></div>
								</td>
								<td>
									<div class="skeleton-line"></div>
								</td>
								<td>
									<div class="skeleton-line"></div>
								</td>
								<td>
									<div class="skeleton-line"></div>
								</td>
								<td>
									<div class="skeleton-line"></div>
								</td>
							</tr> -->
						</tbody>
						<!-- <tbody v-if="loading">
							<tr v-for="i in 5">
								<td>
									<div class="skeleton-avatar avatar-sm avatar-rounded">
									</div>
								</td>
								<td>
									<div class="skeleton-line"></div>
								</td>
								<td>
									<div class="skeleton-line"></div>
								</td>
								<td>
									<div class="skeleton-line"></div>
								</td>
								<td>
									<div class="skeleton-line"></div>
								</td>
								<td>
									<div class="skeleton-line"></div>
								</td>
							</tr>
						</tbody> -->
					</table>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
  import appUtils from '../../utils/appUtils'
  import {ExportToCsv} from 'export-to-csv'
  export default {
    name: 'PersonList',
    props: ['persons'],
    data () {
      return {
        loading: false,
        name_user: '905',
        sort_create: 2,
        sort_last: 0
      }
    },
    mounted () {

    },
    methods: {
      hasAvatar (person) {
        return person && person.avatar
      },
      getImageURL (avatar) {
        return appUtils.getImageURL(avatar)
      },
      formatDateDMY (date_time) {
        return window.moment(date_time).format('DD/MM/YYYY HH:mm')
      },
      formatWeekdayTimev2 (date_time) {
        let curr_week = window.moment().week()
        let date_time_week = window.moment(date_time).week()
        if (curr_week === date_time_week) {
          return window.moment(date_time).fromNow()
        } else {
          var split_time = window.moment(date_time).format('DD/MM/YYYY HH:mm').split(' ')
          var str_time = split_time[1] + '<br><small class="robo-12-400 text-muted">' + split_time[0] + '</small>'
          return str_time
        }
      },
      sortByCreatedAt () {
        let self = this
        self.sort_last = 0
        self.sort_create = self.sort_create + 1
        if (self.sort_create >= 3) {
          self.sort_create = 0
        }
        self.$emit('sortPerson', self.sort_create, self.sort_last)
      },
      sortByLastLogin () {
        let self = this
        self.sort_create = 0
        self.sort_last = self.sort_last + 1
        if (self.sort_last >= 3) {
          self.sort_last = 0
        }
        self.$emit('sortPerson', self.sort_create, self.sort_last)
      },
      async donwloadFileExcPerson () {
        let self = this
        try {
          if (self.persons) {
            var data = []
            var params = {
              limit: self.persons.total,
              order: 'desc',
              order_by: 'created_at',
              start_time: window.moment(new Date()).subtract(3, 'days').hours(0).minutes(0).seconds(0).milliseconds(0).format('HH:mm DD-MM-YYYY'),
              end_time: window.moment(new Date()).endOf('day').format('HH:mm DD-MM-YYYY')
            }
            var list_result = await self.$rf.getRequest('AdminRequest').getUser(params)
            if (list_result.data.total > 0) {
              list_result.data.data.forEach((item, index) => {
                var name = item && item.person && item.person.name ? item.person.name : ''
                var age = item && item.person && item.person.age ? item.person.age : ''
                var phonenumber = item && item.username ? item.username : ''
                var career = item && item.person && item.person.career ? item.person.career : ''
                var otp = item && item.otp ? item.otp : ''
                var lasttime = item && item.last_login ? item.last_login : ''
                var address = item && item.person && item.person.address ? item.person.address : ''
                var createat = item && item.created_at ? item.created_at : ''
                let obj = {
                  'STT': index + 1,
                  'Họ tên bệnh nhân': name,
                  'Tuổi': age,
                  'Số điện thoại': phonenumber,
                  'Nghề nghiệp': career,
                  'OTP': otp,
                  'Địa chỉ': address,
                  'Last login': lasttime,
                  'Create at': createat
                }
                data.push(obj)
              })
            }
            let file_names = []
            file_names.push('BC-BN-GĐ')
            const options = {
              filename: file_names.join('_'),
              fieldSeparator: ',',
              quoteStrings: '"',
              decimalSeparator: '.',
              showLabels: true,
              showTitle: false,
              title: 'Transactions Export',
              useTextFile: false,
              useBom: true,
              useKeysAsHeaders: true
            }
            const csvExporter = new ExportToCsv(options)
            return csvExporter.generateCsv(data)
          }
        } catch (e) {

        }
      }
    }
  }
</script>
<style scoped>
.sort-both {
	background-image: url('../../../public/assets/images/icon/sort_both.png');
	background-size: 10px 10px;
	background-repeat: no-repeat;
	background-position: center right;
	cursor: pointer;
}
.sort-down {
	background-image: url('../../../public/assets/images/icon/sort_down.png');
	background-repeat: no-repeat;
	background-position: center right;
	cursor: pointer;
}
.sort-up {
	background-image: url('../../../public/assets/images/icon/sort_up.png');
	background-repeat: no-repeat;
	background-position: center right;
	cursor: pointer;
}
</style>