<template>
    <div class="my-3">
        <div class="d-flex">
            <a href="javascript:;" class="mr-4" :class="showing === 'test' ? 'robo-18-500 active-border bd-pri txt-pri' : 'robo-18-400 normal-type' " :disabled="!disease ? true : false" @click="changeType(1)">Thông tin xét nghiệm 
              <img v-if="disease && disease.sign && disease.sign.test_flag == 1" src="../../../public/assets/images/icon/icon_dot_red.svg" style="height: 12px; width: 12px;">
            </a>
            <a href="javascript:;" class="mr-4" :class="showing === 'pacs' ? 'robo-18-500 active-border bd-pri txt-pri' : 'robo-18-400 normal-type' " :disabled="!disease ? true : false" @click="changeType(11)">PACS 
            </a>
            <a href="javascript:;" class="mr-4" :class="showing === 'health-information' ? 'robo-18-500 active-border bd-pri txt-pri' : 'robo-18-400 normal-type' " :disabled="!disease ? true : false" @click="changeType(9)">
              Thông tin sức khoẻ 
              <img src="../../../public/assets/images/icon/icon_dot_red.svg" v-if="disease && disease.sign && disease.health_flag === 1" style="height: 12px; width: 12px;">
            </a>

            <a href="javascript:;" class="mr-4" :class="showing === 'medicines' || showing === 'medicines' ? 'robo-18-500 active-border bd-pri txt-pri' : 'robo-18-400 normal-type' " :disabled="!disease ? true : false" @click="changeType(2)">
              Thuốc 
              <img v-if="disease && disease.sign && disease.sign.medicine_flag === 1" src="../../../public/assets/images/icon/icon_dot_red.svg" style="height: 12px; width: 12px;">
            </a>
            <a href="javascript:;" class="mr-4" :class="showing === 'advices' ? 'robo-18-500 active-border bd-pri txt-pri' : 'robo-18-400 normal-type' " :disabled="!disease ? true : false" @click="changeType(10)">
              Lời dặn
            </a>
            <a href="javascript:;" class="mr-4" :class="showing === 'documents' || showing === 'documents' ? 'robo-18-500 active-border bd-pri txt-pri' : 'robo-18-400 normal-type' " :disabled="!disease ? true : false" @click="changeType(3)">Tài liệu</a>
            <a href="javascript:;" class="mr-4" :class="showing === 'schedules' || showing === 'schedules' ? 'robo-18-500 active-border bd-pri txt-pri' : 'robo-18-400 normal-type' " :disabled="!disease ? true : false" @click="changeType(6)">Tuân thủ thuốc</a>
            <!-- <a href="javascript:;" class="mr-4" :class="showing === 'topic' || showing === 'topic' ? 'robo-18-500 active-border bd-pri txt-pri' : 'robo-18-400 normal-type' " :disabled="!disease ? true : false" @click="changeType(7)">
              Tin nhắn 
              <img v-if="disease && disease.sign && disease.sign.diary_flag === 1" src="../../../public/assets/images/icon/icon_dot_red.svg" style="height: 12px; width: 12px;">
            </a> -->
            <a href="javascript:;" class="mr-4" :class="showing === 'health-information-listing' || showing === 'health-information-listing' ? 'robo-18-500 active-border bd-pri txt-pri' : 'robo-18-400 normal-type' " :disabled="!disease ? true : false" @click="changeType(8)">Thông tin khác</a>

            <div class="ml-auto">
              <button class="btn btn-sm radius-0 d-flex align-items-center bg-pri bd-pri text-white robo-18-500 mr-2" v-if="showing === 'test'" @click="changeType(4)">
              <img src="../../../public/assets/images/icon/plus.svg" class="mr-2" style="height: 14px; width: 14px;">  Thêm
              </button>
              <button class="btn btn-sm radius-0 d-flex align-items-center bg-pri bd-pri text-white robo-18-500 mr-2 mb-1" v-if="showing === 'medicines'" @click="showStartMedicine()">
                Bắt đầu thuốc
              </button>
              <button class="btn btn-sm radius-0 d-flex align-items-center bg-pri bd-pri text-white robo-18-500 mr-2" v-if="showing === 'medicines'" @click="changeType(5)">
              <img src="../../../public/assets/images/icon/plus.svg" class="mr-2" style="height: 14px; width: 14px;">  Thêm
              </button>
            </div>
        </div>
    </div>
</template>
<script>
export default {

  name: 'TypeBar',
  props: ['showing', 'person_id', 'disease'],
  data () {
    return {
      type: 1
    }
  },
  mounted () {
  },
  methods: {
    changeType (type) {
      let self = this
      self.type = type
      if (self.disease) {
        if (self.type === 1) {
          self.$router.push({path: `/admin/diseases/${self.$route?.params?.id}/detail`}).catch(_ => {})
        } else if (self.type === 2) {
          self.$router.push({path: `/admin/diseases/${self.$route?.params?.id}/medicine?person=${self.person_id}`}).catch(_ => {})
        } else if (self.type === 3) {
          self.$router.push({path: `/admin/diseases/${self.$route?.params?.id}/document`}).catch(_ => {})
        } else if (self.type === 4) {
          self.$router.push({path: `/admin/diseases/${self.$route?.params?.id}/add`}).catch(_ => {})
        } else if (self.type === 5) {
          self.$router.push({path: `/admin/diseases/${self.$route?.params?.id}/medicine-add?person=${self.person_id}`}).catch(_ => {})
        } else if (self.type === 6) {
          self.$router.push({path: `/admin/diseases/${self.$route?.params?.id}/medicine-schedules`}).catch(_ => {})
        } else if (self.type === 8) {
          self.$router.push({path: `/admin/diseases/${self.$route?.params?.id}/health-information-listing?person=${self.person_id}`}).catch(_ => {})
        } else if (self.type === 9) {
          self.$router.push({path: `/admin/diseases/${self.$route?.params?.id}/health-information`}).catch(_ => {})
        } else if (self.type === 10) {
          self.$router.push({path: `/admin/diseases/${self.$route?.params?.id}/advices`}).catch(_ => {})
        } else if (self.type === 11) {
          self.$router.push({path: `/admin/diseases/${self.$route?.params?.id}/pacs`}).catch(_ => {})
        }
        // else if (self.type === 7) {
        //   self.$router.push({path: `/admin/diseases/${self.$route?.params?.id}/topic`}).catch(_ => {})
        // }
        self.putPersonsCareplanSign()
      }
    },
    onShowModalUpload (show) {
      console.log('show')
      this.$emit('onShowModalUpload', show)
    },
    async putPersonsCareplanSign () {
      let self = this
      try {
        if (!self.disease.sign) return
        var params = {}
        if (self.type === 1) {
          params.test_flag = 0
        } else if (self.type === 9) {
          params.health_flag = 0
        } else if (self.type === 2) {
          params.medicine_flag = 0
        } else if (self.type === 7) {
          params.diary_flag = 0
        }
        self.$rf.getRequest('AdminRequest').putPersonsCareplanSign(self.disease.sign.id, params).then(res => {
          // self.getPersonsCareplanSign()
        })
      } catch (e) {
        // statements
        console.log(e)
      }
    },
    showStartMedicine () {
      this.$emit('showStartMedicine', true)
    }
  }
}
</script>
<style lang="css" scoped>
.normal-type {
    color: #536CBC;
}
.normal-type:hover {
    color: #20419B !important;
}
.active-border {
  border-bottom: solid 1.5px;
}
a:hover {
  text-decoration: none;
}
a[disabled] {
    /*pointer-events: none;*/
    cursor: not-allowed;
}
</style>